import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import MenuIcon from "../../assets/icons8-menu.svg"; // Update the path to your own menu icon
import logo from "../../assets/logoes.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-slate-300 text-black shadow-md fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-4">
        <div className="flex justify-between items-center h-20">
          <div className="flex-shrink-0 p-4 flex justify-center items-center">
            <img
              src={logo}
              alt="Easy Stocks Logo"
              className="h-[50px] md:h-[70px] my-10 w-auto"
            />
            <div className="flex flex-col ml-3 hidden md:flex">
              <a href="/#home" className="text-xs leading-8 font-bold text-black">
                Easy Stocks
              </a>
              <p className="text-black font-semibold text-xs">Smarter Tracking Faster Growth</p>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <HashLink
                to="/#home"
                smooth
                className="text-black hover:text-blue-600 px-3 py-2 rounded-md text-xs  font-medium cursor-pointer"
              >
                Home
              </HashLink>
              <HashLink
                to="/#features"
                smooth
                className="text-black hover:text-blue-600 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
              >
                Product
              </HashLink>
              <HashLink
                to="/#pricing"
                smooth
                className="text-black hover:text-blue-600 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
              >
               Plans & Pricing
              </HashLink>
              <HashLink
                to="/#partners"
                smooth
                className="text-black hover:text-blue-600 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
              >
                Partners 
              </HashLink>
              <HashLink
                to="/#contact"
                smooth
                className="text-black hover:text-blue-600 px-3 py-2 rounded-md text-xs  font-medium cursor-pointer"
              >
                Contact Us
              </HashLink>
              <a
                href="http://easystock.live/portal"
                target="_blank"
                smooth
                className="text-black hover:text-blue-600 px-3 py-2 rounded-md text-xs  font-medium cursor-pointer"
              >
                Login
              </a>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md hover:text-blue-600 focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <img src={MenuIcon} alt="Menu" className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 text-center pt-2 pb-3 space-y-1 sm:px-3">
            <HashLink
              to="/#home"
              smooth
              className="text-black hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Home
            </HashLink>
            <HashLink
              to="/#features"
              smooth
              className="text-black hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Product
            </HashLink>
            <HashLink
              to="/#pricing"
              smooth
              className="text-black hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Pricing
            </HashLink>
            <HashLink
              to="/#contact"
              smooth
              className="text-black hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Contact
            </HashLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
